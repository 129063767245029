<template>
  <div class="wrapper">
    <div
      class="banner"
      data-aos="fade-down"
      data-aos-delay="300"
    />
    <b-container
      id="formulario"
      class="mt-5 mb-5"
    >
      <b-row>
        <b-col
          class="contact-form agente"
          lg="6"
          data-aos="fade-in"
          data-aos-delay="400"
        >
          <form
            v-if="!sentSuccess"
            class="contact3-form validate-form"
            @submit.prevent="onSubmit"
          >
            <h3>{{ $t('message_form.Déjanos_tus_datos') }}</h3>
            <b-row>
              <b-col lg="6">
                <div class="form-group">
                  <label class="text-white">¿Cual es tu nombre?</label>
                  <b-input
                    v-model="form.name"
                    type="text"
                    placeholder="Tu nombre"
                    class="form-control"
                    required
                  />
                </div>
              </b-col>
              <b-col lg="6">
                <div class="form-group">
                  <label class="text-white">Tu mejor mail</label>
                  <b-input
                    v-model="form.email"
                    type="email"
                    placeholder="Tu mail"
                    class="form-control"
                    required
                  />
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <div class="form-group">
                  <label class="text-white">{{ $t('convenio.Cual_es_tu_situación') }}</label>
                  <div class="input-group">
                    <label class="text-white mr-2"><input
                      v-model="form.situation"
                      type="radio"
                      name="radio0"
                      :value="$t('message_form.empresa')"
                      required
                    > {{ $t('message_form.empresa') }} </label>
                    <label class="text-white"><input
                      v-model="form.situation"
                      type="radio"
                      name="radio0"
                      :value="$t('message_form.practicar')"
                      required
                    > {{ $t('message_form.practicar') }} </label>
                  </div>
                </div>
              </b-col>
              <b-col lg="6">
                <div class="form-group">
                  <label class="text-white">Contactar</label>
                  <div class="input-group">
                    <label class="text-white mr-2"><input
                      v-model="form.time"
                      type="radio"
                      name="radio"
                      :value="$t('message_form.mañana')"
                      required
                    > {{ $t('message_form.mañana') }}</label>
                    <label class="text-white"><input
                      v-model="form.time"
                      type="radio"
                      name="radio"
                      :value="$t('message_form.tarde')"
                      required
                    > {{ $t('message_form.tarde') }} </label>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="6">
                <div class="form-group">
                  <select
                    v-model="form.option"
                    class="form-control"
                    required
                  >
                    <option
                      value=""
                      disabled
                      selected
                    >
                      {{ $t('message_form.opt_edu_noselected') }}
                    </option>
                    <option :value="$t('message_form.opt1')">
                      {{ $t('message_form.opt1') }}
                    </option>
                    <option :value="$t('message_form.opt2')">
                      {{ $t('message_form.opt2') }}
                    </option>
                    <option :value="$t('message_form.opt3')">
                      {{ $t('message_form.opt3') }}
                    </option>
                    <option :value="$t('message_form.opt4')">
                      {{ $t('message_form.opt4') }}
                    </option>
                    <option :value="$t('message_form.opt5')">
                      {{ $t('message_form.opt5') }}
                    </option>
                    <option :value="$t('message_form.opt6')">
                      {{ $t('message_form.opt6') }}
                    </option>
                    <option :value="$t('message_form.opt7')">
                      {{ $t('message_form.opt7') }}
                    </option>
                  </select>
                </div>
              </b-col>
              <b-col lg="6">
                <div class="form-group">
                  <select
                    v-model="form.option2"
                    class="form-control"
                    required
                  >
                    <option
                      value=""
                      disabled
                      selected
                    >
                      {{ $t('message_form.opt_for_noselected') }}
                    </option>
                    <option :value="$t('message_form.opti1')">
                      {{ $t('message_form.opti1') }}
                    </option>
                    <option :value="$t('message_form.opti2')">
                      {{ $t('message_form.opti2') }}
                    </option>
                    <option :value="$t('message_form.opti3')">
                      {{ $t('message_form.opti3') }}
                    </option>
                    <option :value="$t('message_form.opti4')">
                      {{ $t('message_form.opti4') }}
                    </option>
                  </select>
                </div>
              </b-col>
            </b-row>

            <div class="form-group">
              <label class="text-white">¿A qué numero te llamamos?</label>
              <b-input
                v-model="form.number"
                type="tel"
                placeholder="Su teléfono"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label class="text-white">{{ $t('message_form.mensaje') }}</label>
              <b-textarea
                v-model="form.message"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <ul class="list">
                <li>{{ $t('convenio.RGPD_youcan') }} <a href="/aviso-legal-privacidad/">{{ $t('convenio.RGPD_policy') }}</a></li>
                <li><strong>{{ $t('convenio.RGPD_responsible_catp') }}</strong>: {{ $t('convenio.RGPD_responsible_answ') }}</li>
                <li><strong>{{ $t('convenio.RGPD_finalidad') }}</strong>: {{ $t('convenio.RGPD_info') }}</li>
                <li><strong>{{ $t('convenio.RGPD_almacenamiento') }}</strong>: {{ $t('convenio.RGPD_almacenamiento_anws') }}</li>
                <li><strong>{{ $t('convenio.RGPD_right') }}</strong>: {{ $t('convenio.RGPD_right_anws') }} <a href="https://www.websincoste.com/page/aviso-legal-privacidad/">{{ $t('convenio.RGPD_limits') }}</a></li>
              </ul>
              <div class="text-white">
                <input
                  id="RGPD_OPTIN"
                  v-model="form.checked"
                  type="checkbox"
                  class="check"
                  required
                >
                {{ $t('convenio.He_leído') }}<a
                  href="/aviso-legal-privacidad/"
                  target="_blank"
                >{{ $t('convenio.políticas_de_privacidad') }}</a>
              </div>
            </div>
            <div class="form-group">
              <button
                id="send-btn"
                class="sib-form-block__button"
                type="submit"
              >
                {{ $t('common_contact_form.send') }}
              </button>
              <h3
                v-if="sentFailed"
                style="color: #ff9ca6;"
              >
                Error: ¡Inténtalo de nuevo!
              </h3>
            </div>
          </form>
          <div
            v-else-if="sentSuccess"
            class="send-success"
          >
            <h3>{{ $t('common_contact_form.thanks_message') }}</h3>
            <h3 style="color:#fff;">
              {{ $t('common_contact_form.will_contact') }}
            </h3>
            <div
              class="contact-info text-center mt-4"
              style="color:#fff"
            >
              <h4>Puedes contactarme por</h4>
              <div class="contact-nfo">
                <font-awesome-icon
                  icon="phone"
                  class="phone-icon"
                />
                <a
                  href="tel:+34928354668"
                  style="color:#fff"
                >+34 928 354 668</a>
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          lg="6"
          class="first-right-text"
        >
          <div class="right-text">
            <p>
              Siguiendo la filosofía de nuestra área de “Certificación de personas” vemos enormes posibilidades en la incorporación de la figura del “Agente Turístico Cualificado”, siguiendo una estela de experiencia con empresas del sector. Detectamos ciertas carencias en el trabajador del sector, incluso diríamos en todos los profesionales que en su desempeño tienen trato con el turista, estas carencias vienen determinadas en muchos casos por la formación y el enfoque que le da el empresario, aspectos que son influyentes y que hay que dominar: idiomas, cultura e identidad del visitante, calidad, servicios, información, accesibilidad, soportes de gestión, guía y promoción turística, criterios de sostenibilidad y responsabilidad social por citar los más importantes.
            </p>
          </div>
        </b-col>
        <b-col lg="1" />
        <b-col
          lg="12"
          class="right-text second-right-text"
        >
          <p>
            Los Modelos de Gestión Turística que conocemos así como las entidades y organismos que fomentan la calidad, la formación y los servicios al turismo, aportan la base objetiva para gestionar un sector de vital importancia para nuestra economía. Desde Aicad tenemos un conocimiento de las magníficas herramientas que están a nuestra disposición para aumentar y consolidar los estándares de calidad y servicio al turista, pero también hemos analizado que el sector es sensible a la mejora desde todas las ópticas profesionales posibles y aparecen iniciativas tecnológicas constantemente que facilitan este trabaj
          </p>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="mt-5 mb-3">
      <b-row>
        <b-col
          lg="4"
          data-aos="fade-down"
          data-aos-delay="300"
        >
          <div class="text-container">
            <h2>
              {{ $t('agente_turistico.TURISMO') }}
            </h2>
            <h3>
              {{ $t('agente_turistico.principal') }}
            </h3>
          </div>
        </b-col>
        <b-col
          lg="4"
          data-aos="fade-down"
          data-aos-delay="200"
        >
          <div class="text-container">
            <h2>
              {{ $t('agente_turistico.VENTAJAS') }}
            </h2>
            <h3>
              {{ $t('agente_turistico.Certiﬁcado') }}
            </h3>
          </div>
        </b-col>
        <b-col
          lg="4"
          data-aos="fade-down"
          data-aos-delay="300"
        >
          <div class="text-container">
            <h2>
              {{ $t('agente_turistico.VALORES') }}
            </h2>
            <h3>
              {{ $t('agente_turistico.comprender') }}
            </h3>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container
      class="section-black"
      data-aos="fade-right"
      data-aos-delay="300"
    >
      <b-row>
        <b-col
          lg="6"
          md="12"
        >
          <h2>{{ $t('agente_turistico.ﬁlosofía') }}</h2>
          <h3>{{ $t('agente_turistico.apostamos') }}</h3>
          <p>
            {{ $t('agente_turistico.existan') }}
          </p>
        </b-col>
        <b-col
          lg="6"
          md="12"
        >
          <img
            src="../assets/img/agente_turistico/team-photo.jpg"
            class="youtube"
            alt="aicad-team-photoshoot"
          >
          <div
            class="box"
            data-aos="fade-left"
            data-aos-delay="300"
          >
            <h3>
              <span style="font-weight: bolder">{{ $t('agente_turistico.podrá') }}<br></span>
            </h3>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="section6 mt-5">
      <h2>{{ $t('agente_turistico.Modulares') }}</h2>
      <b-row>
        <b-col lg="6">
          <b-row
            class="box"
            data-aos="zoom-in-up"
            data-aos-delay="200"
          >
            <b-col lg="11">
              <h3>{{ $t('agente_turistico.medio') }}</h3>
              <template v-if="show1 === true">
                <h3 />
              </template>
            </b-col>
            <b-col lg="1" />
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-row
            class="box"
            data-aos="zoom-in-up"
            data-aos-delay="300"
          >
            <b-col lg="10">
              <h3>{{ $t('agente_turistico.global') }}</h3>
              <template v-if="show2 === true">
                <h3 />
              </template>
            </b-col>
            <b-col lg="1" />
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-row
            class="box"
            data-aos="zoom-in-up"
            data-aos-delay="300"
          >
            <b-col lg="11">
              <h3>{{ $t('agente_turistico.visitantes') }}</h3>
              <template v-if="show3 === true">
                <h3 />
              </template>
            </b-col>
            <b-col lg="1" />
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-row
            class="box"
            data-aos="zoom-in-up"
            data-aos-delay="200"
          >
            <b-col lg="10">
              <h3>{{ $t('agente_turistico.turista') }}</h3>
              <template v-if="show4 === true">
                <h3 />
              </template>
            </b-col>
            <b-col lg="1" />
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-row
            class="box"
            data-aos="zoom-in-up"
            data-aos-delay="200"
          >
            <b-col lg="11">
              <h3>{{ $t('agente_turistico.servicios') }}</h3>
              <template v-if="show5 === true" />
            </b-col>
            <b-col lg="1" />
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-row
            class="box"
            data-aos="zoom-in-up"
            data-aos-delay="300"
          >
            <b-col lg="10">
              <h3>{{ $t('agente_turistico.social') }}</h3>
              <template v-if="show6 === true" />
            </b-col>
            <b-col lg="1" />
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-row
            class="box"
            data-aos="zoom-in-up"
            data-aos-delay="300"
          >
            <b-col lg="11">
              <h3>{{ $t('agente_turistico.canales') }}</h3>
              <template v-if="show7 === true" />
            </b-col>
            <b-col lg="1" />
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-row
            class="box"
            data-aos="zoom-in-up"
            data-aos-delay="200"
          >
            <b-col lg="10">
              <h3>{{ $t('agente_turistico.guia') }}</h3>
              <template v-if="show8 === true" />
            </b-col>
            <b-col lg="1" />
          </b-row>
        </b-col>
        <b-col lg="12">
          <b-row
            class="box"
            data-aos="zoom-in-up"
            data-aos-delay="200"
          >
            <b-col lg="10">
              <h3>{{ $t('agente_turistico.orientacion') }}</h3>
              <template v-if="show9 === true" />
            </b-col>
            <b-col lg="1" />
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="section7">
      <h2
        data-aos="zoom-in-up"
        data-aos-delay="300"
      >
        {{ $t('agente_turistico.papel') }}
      </h2>
      <h3
        data-aos="zoom-in-up"
        data-aos-delay="200"
      >
        {{ $t('agente_turistico.juegan') }}
      </h3>
      <h3
        data-aos="fade-in"
        data-aos-delay="400"
      >
        {{ $t('agente_turistico.relacion') }}
      </h3>
      <h3
        data-aos="fade-in"
        data-aos-delay="400"
      >
        {{ $t('agente_turistico.claro') }}
      </h3>
      <b-button
        v-smooth-scroll="{ duration: 1500, offset: -100 }"
        class="btn"
        data-aos="zoom-in-up"
        data-aos-delay="300"
        :to="{ path: '#formulario' }"
      >
        {{ $t('agente_turistico.Formulario') }}
      </b-button>
    </b-container>
    <div class="people">
      <b-container class="big">
        <h3 style="text-align: center">
          {{ $t('agente_turistico.estamos') }}
        </h3>
        <h2 class="h2-very-bg">
          {{ $t('agente_turistico.de_que_no') }}
        </h2>
        <b-row>
          <b-col lg="6">
            <p>{{ $t('agente_turistico.como') }}</p>
          </b-col>
          <b-col lg="6">
            <p>{{ $t('agente_turistico.sin') }}</p>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
    name: 'AgenteTuristico',
    data() {
        return {
            show1: false,
            show2: false,
            show3: false,
            show4: false,
            show5: false,
            show6: false,
            show7: false,
            show8: false,
            show9: false,
            checkedNames: [],
            form: {
                name: '',
                email: '',
                number: '',
                option: '',
                option2: '',
                situation: '',
                time: '',
                message: '',
                checked: ''
            },
            status: 'not_accepted',
            sentSuccess: false,
            sentFailed: false
        };
    },
    methods: {
        onSubmit() {
            event.preventDefault();
            this.axios
                .post('https://www.websincoste.com/index.php', {
                    _nonce: 'f9d8d3d927baca5e53d95c32b41f6f00',
                    action: 'send_universal_form',
                    serialize: [{ name: 'Form Type', value: 'Agente Turistico Form' }, { name: 'Nombre', value: this.form.name }, { name: 'Email', value: this.form.email }, { name: 'Telefono', value: this.form.number }, { name: 'Opcion 1', value: this.form.option }, { name: 'Opcion 2', value: this.form.option2 }, { name: 'Situación', value: this.form.situation }, { name: 'Contactar', value: this.form.time }, { name: 'Mensaje', value: this.form.message }, { name: 'Reference Link', value: window.location.href }, { name: 'Aceptación Privacidad RGDP:', value: this.form.checked }]
                })
                .then(response => {
                    if (response.status === 200) {
                        this.sentSuccess = true;
                    }
                })
                .catch(error => {
                    this.sentFailed = true;
                    this.sentSuccess = false;
                    console.log(error);
                });
        }
    }
};
</script>

<style scoped lang="scss">
.wrapper {
	.first-right-text {
		padding: 0px 50px;
	}
	.second-right-text {
		padding-left: 0px;
		padding-top: 30px;
	}
	.agente.contact-form .send-success {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		height: 100%;
	}
	.h2-bg {
		font-family: LatoWebBlack, sans-serif;
		font-size: 10vh;
		text-align: center;
		color: #000;
		@media only screen and (max-width: 424px) {
			font-size: 3rem;
		}
	}
	.h2-very-bg {
		font-family: LatoWebBlack, sans-serif !important;
		font-size: 9vh !important;
		text-align: center !important;
		color: whitesmoke !important;
		@media only screen and (max-width: 424px) {
			font-size: 3rem !important;
		}
	}
	.p-def {
		font-family: LatoWebBold, sans-serif;
		text-align: center;
		color: #555555;
	}
	.banner {
		background-image: url('../assets/img/agente_turistico/agente_turistico-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: left center;
		min-height: 300px;
		text-align: right;
		padding-right: 50px;
		padding-left: 50px;
		@media only screen and (max-width: 424px) {padding-right: 15px;
			padding-left: 15px;
		}

		.text {
			max-width: 500px;
			width: 100%;
			float: right;
			position: relative;
			top: 200px;

			h2 {
				font-family: LatoWebBlackItalic, sans-serif;
				font-size: 2.5em;
				color: white;
				@media only screen and (max-width: 424px) {
					font-size: 1.7em;
					color: white;
				}
			}

			.hr {
				height: 2px;
				width: 100%;
				background-color: white;
				margin-bottom: 10px;
			}

			h3 {
				font-family: LatoWebMedium, sans-serif;
				font-size: 1.5rem;
				color: white;
			}

			.btn {
				border: none;

				background-color: white;
				font-family: LatoWebMedium, sans-serif;
				width: 100%;
				color: #008bf2;
			}
		}
	}
	.contact-form {
		margin-top: -130px;
		background-color: rgba(0, 0, 0, 0.8);
		text-align: left;
		padding: 20px;
		@media only screen and (max-width: 768px) {
			margin-bottom: 50px;
		}

		h3 {
			width: 100%;
			font-family: LatoWebBold, sans-serif;
			color: whitesmoke;
			font-size: 1.5rem;
		}

		.field {
			width: 100%;
			border-radius: 0px;
			margin-bottom: 10px;
		}

		.drop {
			width: 100%;
			border-radius: 0px;
			margin-bottom: 10px;
			background-color: white !important;
		}

		p {
			text-align: left;
			color: whitesmoke;
		}

		.check {
			text-align: left;
			color: white;
		}

		.list {
			text-align: left;
			color: whitesmoke;
			list-style: square;
		}

		@media only screen and (max-width: 424px) {
			margin-top: -50px;
		}
	}
	.right-text {
		h2 {
			color: #009090;
			font-family: LatoWebBlack, sans-serif;
			font-size: 5rem;
			letter-spacing: 2px;
			@media only screen and (max-width: 424px) {
				font-size: 3rem;
			}
		}

		h3 {
			font-family: LatoWebLight, sans-serif;
		}

		p {
			font-family: LatoWebSemiboldItalic, sans-serif;
			font-size: 1.35rem;
			color: #3d3d3d;
		}
	}
	.text-container {
		text-align: center;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		padding: 20px;
		border-top: #2a89b9 10px solid;
		background-color: white;
		-webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
		box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);

		h2 {
			font-family: LatoWebBlack, sans-serif;
			font-size: 1.5rem;
			color: #4b4b4b;
		}

		h3 {
			font-family: LatoWebLight, sans-serif;
			font-size: 1.2rem;
		}

		@media only screen and (max-width: 424px) {
			padding: 50px;
			margin-top: 0;
			width: 100%;
		}
		@media only screen and (max-width: 776px) {
			margin-top: 0;
			width: 100%;
		}
	}
	.section4 {
		background-image: url('../assets/img/convenio_practicas/background-girl.png');
		background-attachment: fixed;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		height: auto;
		text-align: center;
		padding: 20px;
		@media only screen and (max-width: 424px) {
			padding: 50px 0 50px 0;
		}

		h2 {
			align-content: center;
			font-family: LatoWebBlack, sans-serif;
			font-size: 8vh;
			min-font-size: 500px;
			letter-spacing: 7px;
			color: white;
			@media only screen and (max-width: 430px) {
				font-size: 5vh;
			}
		}

		h3 {
			align-content: center;
			font-family: LatoWebLight, sans-serif;
			font-size: 4vh;
			min-font-size: 500px;
			color: white;
		}

		h3 {
			padding-left: 100px;
			padding-right: 100px;
			text-align: left;
			font-family: LatoWebLight, sans-serif;
			font-size: 1.5vh;
			min-font-size: 500px;
			color: white;
			@media only screen and (max-width: 776px) {
				padding-left: 50px;
				padding-right: 50px;
				font-size: 2vh;
			}
			@media only screen and (max-width: 364px) {
				padding-left: 15px;
				padding-right: 15px;
			}
		}

		.white-box {
			text-align: center;
			width: 80%;
			height: 50%;
			margin: 0 auto;
			padding: 80px;
			border-top: #2a89b9 10px solid;
			background-color: white;
			-webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
			-moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
			box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);

			h2 {
				font-family: LatoWebRegular, sans-serif;
				font-size: 1rem;
				color: #4b4b4b;
				letter-spacing: 2px;
				text-align: left;
				margin-bottom: 20px;
			}

			h3 {
				font-family: LatoWebLight, sans-serif;
				font-size: 1.2rem;
			}

			@media only screen and (max-width: 424px) {
				padding: 15px;
				margin-top: 0;
				width: 100%;
			}
			@media only screen and (max-width: 776px) {
				margin-top: 0;
				width: 100%;
			}
		}
	}
	.section-black {
		background: linear-gradient(90deg, rgba(0, 130, 195, 1) 0%, rgba(69, 187, 198, 1) 54%, rgba(197, 224, 195, 1) 100%);
		height: auto;
		padding: 100px;
		@media only screen and (max-width: 430px) {
			margin-top: -100px;
			padding: 80px 20px 20px 20px;
		}

		h2 {
			align-content: center;
			text-align: right;
			font-family: LatoWebBlack, sans-serif;
			font-size: 5vh;
			min-font-size: 500px;
			letter-spacing: 7px;
			color: white;
			@media only screen and (max-width: 430px) {
				font-size: 5vh;
			}
		}

		h3 {
			align-content: center;
			text-align: right;
			font-family: LatoWebMediumItalic, sans-serif;
			font-size: 3vh;
			min-font-size: 500px;
			letter-spacing: 7px;
			color: white;
			@media only screen and (max-width: 430px) {
				font-size: 3vh;
			}
		}

		.box {
			h3 {
				padding: 15px;
				background: linear-gradient(90deg, rgba(90, 139, 166, 1) 0%, rgba(192, 255, 241, 0.48503151260504207) 100%);
				align-content: center;
				text-align: right;
				font-family: LatoWebMedium, sans-serif;
				font-size: 1.8rem;
				color: white;
			}

			@media only screen and (max-width: 1024px) {
				background: transparent;
				left: 0;
			}
		}

		p {
			align-content: center;
			text-align: right;
			font-family: LatoWebLight, sans-serif;
			font-size: 1.2rem;
			color: white;
		}
	}
	.youtube {
		width: 100%;
		position: relative;
		z-index: 51;
	}
	.section6 {
		text-align: center;

		h2 {
			font-family: LatoWebBlack, sans-serif;
			font-size: 6vh;
			letter-spacing: 7px;
			color: #585858;
			@media only screen and (max-width: 424px) {
				font-size: 7vh;
			}
		}

		h3 {
			font-family: LatoWebMedium, sans-serif;
			font-size: 1.1rem;
			color: #000;
		}

		.box {
			margin: 0 auto;
			margin-bottom: 50px;
			justify-content: center;
			padding: 20px;
			text-align: left;
			width: 95%;
			background-color: whitesmoke;
			height: auto;
			transition: 0.3s ease;

			.icon {
				transition: 0.3s ease;

				&:hover {
					cursor: pointer;
					transform: scale(1.2);
				}
			}
		}
	}
	.section7 {
		position: relative;
		text-align: center;
		padding: 20px;
		margin-bottom: 100px;
		background-color: whitesmoke;

		h2 {
			align-content: center;
			font-family: LatoWebBlack, sans-serif;
			font-size: 5rem !important;
			min-font-size: 500px;
			letter-spacing: 7px;
			color: #585858;
			@media only screen and (max-width: 430px) {
				font-size: 4vh;
			}
			@media only screen and (min-width: 1900px) {
				font-size: 6rem;
			}
		}

		h3 {
			align-content: center;
			font-family: LatoWebBlack, sans-serif;
			font-size: 4vh;
			min-font-size: 500px;
			color: #585858;
		}

		h3 {
			padding-left: 200px;
			padding-right: 200px;
			text-align: center;
			font-family: LatoWebMediumItalic, sans-serif;
			font-size: 16px;
			line-height: 1.5em;
			min-font-size: 500px;
			color: #474747;
			@media only screen and (max-width: 776px) {
				padding-left: 50px;
				padding-right: 50px;
			}
			@media only screen and (max-width: 364px) {
				padding-left: 15px;
				padding-right: 15px;
			}
		}

		.btn {
			border: none;

			background-color: white;
			font-family: LatoWebBlack, sans-serif;
			width: 20%;
			min-width: 200px;
			color: black;
			transition: 0.3s ease;

			&:hover {
				cursor: pointer;
				transform: scale(1.2);
			}
		}
	}
	.background {
		background-color: whitesmoke;
		height: 700px;
		width: 90%;
		max-width: 750px;
	}
	.people {
		background: linear-gradient(236deg, rgba(77, 77, 77, 0.7) 0%, rgba(77, 77, 77, 0.7) 100%), url('../assets/img/agente_turistico/angry-woman.jpg'), no-repeat;
		background-size: cover;
		background-position: bottom center;
		height: auto;
		.row {
			margin-top: 100px;
			padding: 50px;
			@media only screen and (max-width: 424px) {
				margin-top: 0;
				padding: 25px;
			}
		}
		img {
			-webkit-box-shadow: 4px 10px 17px 0px rgba(78, 80, 102, 1);
			-moz-box-shadow: 4px 10px 17px 0px rgba(78, 80, 102, 1);
			box-shadow: 4px 10px 17px 0px rgba(78, 80, 102, 1);

			width: 100%;
			max-width: 500px;
			min-width: 200px;
		}

		h2 {
			font-family: LatoWebBold, sans-serif;
			font-size: 8vh;
			color: white;
			@media only screen and (max-width: 424px) {
				font-size: 3rem;
			}
		}

		h3 {
			font-family: LatoWebMedium, sans-serif;
			font-size: 5vh;
			color: white;
			@media only screen and (max-width: 424px) {
				font-size: 2.5rem;
			}
		}

		p {
			margin: 0 auto;
			text-align: center;
			font-family: LatoWebMedium, sans-serif;
			color: white;
			max-width: 300px;
			@media only screen and (max-width: 424px) {
				font-size: 1.5rem;
			}
		}
		.big {
			padding: 150px 0;
			@media only screen and (max-width: 424px) {
				padding: 0px 0;
			}
		}
	}
	.tenth {
		margin-top: -110px;
		background-image: url('../assets/img/practica/green-flesh.png');
		background-repeat: no-repeat;
		background-position: center;
		height: 500px;
		background-size: cover;
		text-align: center;

		h2 {
			padding-top: 280px;
			align-content: center;
			font-family: LatoWebBlack, sans-serif;
			font-size: 8vh;
			letter-spacing: 7px;
			color: white;
			@media only screen and (max-width: 430px) {
				font-size: 5vh;
				margin-top: -20px;
			}
		}
	}
	.feedback {
		position: relative;
		z-index: 99;
		margin: -100px 0 0 0;
		text-align: center;
		img {
			width: 100%;
			max-width: 600px;
			min-width: 300px;
		}
	}
}
</style>
